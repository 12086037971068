
import Vue from 'vue';
import PageCard from '@/common/template/PageCard.vue';

export default Vue.extend({
  name: 'Default',
  components: {
    PageCard,
  },
});
